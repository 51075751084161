<template>
  <div class="loovee-page"></div>
</template>
<script>
import { getAppStatus, callAppHandler } from '@/utils/app';
import { getLooveeLinkApi } from '@/api/base1';

export default {
  name: 'Loovee',
  data() {
    return {
      cuserId: getAppStatus().cuserId,
    };
  },
  computed: {
    cuserIds() {
      // return 'e56ad14f-a5f7-43da-bc9b-9176838a3b96';
      return getAppStatus().cuserId;
    },
  },
  mounted() {
    document.title = '在线抓娃娃';
    this.cuserId = sessionStorage.getItem('userId') || this.cuserId;
    this.cuserId && this.getData();
    if (!this.cuserId) {
      callAppHandler('jzUserId', null, (res) => {
        this.cuserId = res;
        sessionStorage.setItem('userId', res);
        this.getData();
      });
    }
  },
  methods: {
    async getData() {
      let res;
      const { cuserId } = this;
      try {
        res = await getLooveeLinkApi({ cuserId });
      } catch (e) {
        console.log(e);
        this.$toast(e.desc || '网络异常');
      }
      let isLogin = !!this.cuserId;
      window._czc && window._czc.push(['_setCustomVar', '是否登录', isLogin, 7200]);
      if (res?.code === 1) {
        window.open(res.results, '_self');
      }
    },
  },
};
</script>
<style scoped>
.loovee-page {
  width: 100%;
  min-height: 100%;
  background-color: #fff;
}
</style>
